import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";
import Image from "next/image";

//Partnership & Collaborations
import BitVenus from "@/assets/images/icons/featureIn/Collaborations/BitVenus.webp";
import BYBITs from "@/assets/images/icons/featureIn/Collaborations/BYBIT.webp";
import CERTIK from "@/assets/images/icons/featureIn/Collaborations/CERTIK.webp";
import CyberScope from "@/assets/images/icons/featureIn/Collaborations/CyberScope.webp";
import GateIo from "@/assets/images/icons/featureIn/Collaborations/Gate.io.webp";
import MEXC from "@/assets/images/icons/featureIn/Collaborations/MEXC.webp";
import Toobit from "@/assets/images/icons/featureIn/Collaborations/Toobit.webp";
import NDSS from "@/assets/images/icons/featureIn/Collaborations/NDSS.webp";

import Biconomy from "@/assets/images/icons/featureIn/Collaborations/Biconomy.webp";
import Bigone from "@/assets/images/icons/featureIn/Collaborations/Bigone.webp";
import Group from "@/assets/images/icons/featureIn/Collaborations/Group.webp";
import Tapbit from "@/assets/images/icons/featureIn/Collaborations/Tapbit.webp";

function PartnershipCollaborationsSection() {
  return (
    <>
      <div className="featurein center">
        <div className="featurein__header ">
          <div className="hot__title h3 burst center home__featureIn_center home__featureIn_margin-r">
            <BurstPuckerImageTitles />
            <span className="hot__Partnership home__featureIn_width  ">
              Partnership and Collaborations
            </span>
            <BurstPuckerImageTitles />
          </div>
        </div>
        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.mexc.com/tokens/SEED"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={128}
                  height={23}
                  loading="lazy"
                  src={MEXC}
                  alt="MEXC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              MEXC provides fast and secure digital asset trading services
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://announcements.bybit.com/article/bybit-web3-x-seed-photo-exclusive-launch-of-two-award-winning-photographic-nft-collection-bltda2e2e914ea23aa1/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={117}
                  height={40}
                  loading="lazy"
                  src={BYBITs}
                  alt="BYBIT"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Crypto trading experience elevated. Buy, sell, trade BTC,
              alt-coins & NFTs.
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://ndss.org/"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={80}
                  height={22}
                  loading="lazy"
                  src={NDSS}
                  alt="NDSS"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              NDSS empowers individuals with Down syndrome and their families
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://skynet.certik.com/projects/seed-photo"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={163}
                  height={40}
                  loading="lazy"
                  src={CERTIK}
                  alt="CERTIK"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              Blockchain and DeFi ranking platform for analysis and monitoring
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.gate.io/web3/dapps/dapp-detail?id=1511"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={96}
                  height={22}
                  loading="lazy"
                  src={GateIo}
                  alt="GateIo"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Leading cryptocurrency exchange with over 1700+ cryptocurrencies
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.cyberscope.io/audits/1-seed"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={128}
                  height={23}
                  loading="lazy"
                  src={CyberScope}
                  alt="CyberScope"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Providing smart contract audit and cybersecurity services
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.toobit.com/en-US/spot/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={128}
                  height={23}
                  loading="lazy"
                  src={Toobit}
                  alt="Toobit"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Toobit is the easiest, safest, and most rewarding crypto trading
              platform
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.bvox.com/en-US/exchange/SEED/USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={170}
                  height={39}
                  loading="lazy"
                  src={BitVenus}
                  alt="BitVenus"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              BitVenus: Enjoy safe and stable trading for BTC, ETH, and more
            </div>
          </div>
        </div>

        <div className="featurein__container center">
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.sandbox.game/en/sign/?redirectTo=%2Fen%2Fexperiences%2FLens%2520Gallery%2520by%2520Seed.photo%2Fcefb769f-dd44-4999-9333-2186c4d6c15d%2Fhub%2F"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={127}
                  height={50}
                  loading="lazy"
                  src={Group}
                  alt="MEXC"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">An immersive online gaming platform</div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.tapbit.com/spot/exchange/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={126}
                  height={44}
                  loading="lazy"
                  src={Tapbit}
                  alt="BYBIT"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              Where you can buy and sell Bitcoin and other top crypto currencies
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://big.one/en/trade/SEED-USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={125}
                  height={47}
                  loading="lazy"
                  src={Bigone}
                  alt="NDSS"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              A trading platform you can exchange and store cryptocurrencies
            </div>
          </div>
          <div className="featurein_description">
            <div className="heading">
              <Link
                href="https://www.biconomy.com/exchange/SEED_USDT"
                target="_blank"
                className="featurein__item"
              >
                <Image
                  width={193}
                  height={60}
                  loading="lazy"
                  src={Biconomy}
                  alt="CERTIK"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </Link>
            </div>
            <div className="Body">
              {" "}
              Operates the worlds biggest Bitcoin exchange and Altcoins
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnershipCollaborationsSection;
